import { navigate, Link } from "gatsby";
import React, { useState } from "react";
import {
  RealmAppProvider,
  useRealmApp,
  FACILITATOR_APP_ID,
} from "../../components/realm_app";
import OnboardingRedirector from "../../components/facilitator/onboarding_redirector";
import Navbar from "../../components/onboarding_navbar";
import { Form } from '../../components/Form/Form';
import '../../../src/index.css';

const LoginPage = () => {
  const realmApp = useRealmApp();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");

  //TODO: Think of better place to store this data
  const formContent = [
    {'heading': 'Email:', 'type': 'email', 'value': email, 'func': setEmail},
    {'heading': 'Password:', 'type': 'password', 'value': password, 'func': setPassword}
  ];

  async function handleLogin(event) {
    event.preventDefault();
    setLoading("Logging in...");

    try {
      await realmApp.LogIn(email, password);
      navigate("/facilitator/dashboard/");
    } catch (err) {
      console.log(["Error", JSON.stringify(err)]);
      if (err.error == "confirmation required") {
        alert(
          "Please confirm your email by clicking on the confirmation" +
            " email sent to your inbox."
        );
      } else {
        alert("Error: " + err.error);
      }
    }
    setLoading("");
  }

  return (
    <main className='page'>
      <Navbar />
      <div className='page-content-center'>
        <h3>Log In As Facilitator</h3>
      </div>
      <div className='page-content-center'>
        <div className='page-content page-content-small'>
          <Form onSubmitFunc={handleLogin} formContent={formContent} />
        </div>
      </div>
      <h6 className='page-content-center'>
        <Link className='link' to="/facilitator/forgot_password/">Reset Password</Link>
      </h6>
    </main>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <LoginPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};